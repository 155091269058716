// eslint-disable-next-line no-undef
const isDev = ENVIRONMENT === 'development';

let host;

let graphql;

if (isDev) {
  console.log('Using dev constants');
  host = 'http://localhost:4021';
  graphql = 'http://localhost:4021/graphql';
} else {
  console.log('Using production constants');
  host = `https://eg.brevty.com`;
  graphql = 'https://eg.brevty.com/graphql';
}

module.exports = {
  host,
  graphql,
  isDev,
};
